import Amplify, { API, Storage } from "aws-amplify";
// import config from '../conf'

export async function s3Upload(file) {
  const filename = `${Date.now()}-${file.name}`;
  const stored = await Storage.vault.put(filename, file, {
    contentType: file.type
  });

  return stored.key;
}

export async function s3Upload2(file) {
  const filename = `${Date.now()}-${file.name}`;
  const stored = await Storage.put(filename, file, {
    contentType: file.type
  });

  return stored.key;
}

export async function s3UploadPublicRead(file) {
  const filename = `${Date.now()}-${file.name}`;
  const stored = await Storage.put(filename, file, {
    acl: 'public-read',
    contentType: file.type
  });

  return stored.key;
}

/** ************************************************************************************
 * TapTag using EchoLinQ
 ***************************************************************************************/

// 
// v0.1.0
// 
export function apiTagVerifyByHotp(id, counter, hash) {
  try {
    const uid = id || "E0161234567890"
    const cnt = counter || "001224"
    const hotp = hash || "BB7ED7482919531FA9BD0331091DFDA4AA4E2CC6"
    // return API.get("tag", `/v1/auth?uid=${uid}&cnt=${cnt}&hotp=${hotp}`);
    console.log('uid:', uid)
    return API.get("tag", `/auth/v1?uid=${uid}&cnt=${cnt}&hotp=${hotp}`);
  } catch (e) { alert("ERROR: " + e.message) }
}

// 
// v0.2.0
// 
export function apiTagVerifyByHotp2(opts) {
  try {
    const uid = opts.uid || "E0161234567890"
    const cnt = opts.cnt || "001224"
    const hotp = opts.hash || "BB7ED7482919531FA9BD0331091DFDA4AA4E2CC6"
    return API.get("tag", `/ba/${opts.ver}?uid=${uid}&cnt=${cnt}&hotp=${hotp}`);
  } catch (e) { alert("ERROR: " + e.message) }
}

/////////////////////////////////////////////
// Product Provisioning
/////////////////////////////////////////////
export function apiTagCreateProduct(body) {
  try {
    return API.post("tag", `/provision/v1/product`, { body: body });
  } catch (e) { alert("ERROR: " + e.message) }
}

export function apiTagGetProduct(pName, company) {
  try {
    return API.get("tag", `/provision/v1/product?productName=${pName}&company=${company}`);
  } catch (e) { alert("ERROR: " + e.message) }
}

export function apiTagUpdateProduct(pName, company, body) {
  try {
    return API.put("tag", `/provision/v1/product?productName=${pName}&company=${company}`, { body: body });
  } catch (e) { alert("ERROR: " + e.message) }
}

export function apiTagScanProduct() {
  try {
    return API.get("tag", `/provision/v1/product?productName=all`);
  } catch (e) { alert("ERROR: " + e.message) }
}

/////////////////////////////////////////////
// Tag Provisioning
/////////////////////////////////////////////
export function apiTagCreateTag(body) {
  try {
    return API.post("tag", `/provision/v1/tag`, { body: body });
  } catch (e) { alert("ERROR: " + e.message) }
}

export function apiTagProvisionTag(uid, pName, company, body) {
  try {
    return API.post("tag", `/provision/v1/tag/${uid}?productName=${pName}&company=${company}`, { body: body });
  } catch (e) { alert("ERROR: " + e.message) }
}

export function apiTagGetTag(uid) {
  try {
    return API.get("tag", `/provision/v1/tag/${uid}`);
  } catch (e) { alert("ERROR: " + e.message) }
}

/////////////////////////////////////////////
// Ads Provisioning
/////////////////////////////////////////////

export function apiTagListAds(opts) {
  try {
    return API.get("tag", `/ads?adGroup=${opts.adGroup}`);
  } catch (e) { alert("ERROR: " + e.message) }
}

/////////////////////////////////////////////
// Configuration 
/////////////////////////////////////////////
export function apiTagCreateConf(body) {
  try {
    return API.post("tag", `/config`, { body: body });
  } catch (e) { alert("ERROR: " + e.message) }
}

export function apiTagGetConf(confName) {
  try {
    return API.get("tag", `/config?configName=${confName}`);
  } catch (e) { alert("ERROR: " + e.message) }
}

export function apiTagListConf() {
  try {
    return API.get("tag", `/config`);
  } catch (e) { alert("ERROR: " + e.message) }
}

export function apiTagUpdateConf(confName, body) {
  try {
    return API.put("tag", `/config/${confName}`, { body: body });
  } catch (e) { alert("ERROR: " + e.message) }
}

export function apiTagImageList() {
  try {
    return API.get("tag", `/image/list`);
  } catch (e) { alert("ERROR: " + e.message) }
}

/** ************************************************************************************
 * Tag Test using nRF52840
 ***************************************************************************************/
export function apiDrkTagGet() {
  try {
    let msg = "d7ce547fa9a3746aa03caafb4b70bdda31b03db0aaa77d838bf8a6a0e5efb2cd0419a2c816e125dcf986b104dfabf47aac5ce18daacc6fc9048dc21bca66deb32e58af07aff1385fbabb5585d5a890fb9ed7fc3c63672d2f79d2636505034776e8"
    return API.get("tag", `/v1/test?m=${msg}`);
  } catch (e) {
    console.log('apiTagGet Error:', e)
  }
}
