import React, { useEffect, useRef, useState } from "react";
import { apiTagVerifyByHotp2, apiTagListAds } from "../lib/awsLib";
import { API, Storage } from 'aws-amplify';
import queryString from "query-string";
import { FiChevronLeft } from "react-icons/fi";
import { Scrollbars } from 'react-custom-scrollbars';
import Lottie from 'react-lottie';
// import { Button, Card } from "react-bootstrap";
import "./EchoLinq.css";




const VerifiedCard = (props) => {
  return (
    <div style={{ height: "100%" }} >
      {/* <div className="item-1">
        <img src={require('./img/brandAuth_sub.jpg')} style={{ width: "100%" }} />
      </div> */}
      <div className="item item-2">
        <img src={props.imageSrc} alt="verify" style={{ height: "100%", borderRadius: "8px", marginTop: "3%" }} />
      </div>
      <div
        style={{
          marginTop: "-3%"
        }}
      >
        <img
          src={require("./img/stampV2.gif")}
          style={{
            // position: "absolute",
            height: "32%",
            width: "32%",
            marginLeft: "60%",
            // zIndex: 108
            marginTop: "-12%",
            // marginRight: -200
            // backgroundColor: "red",

          }} />

        <h3 className="bold" style={{ textAlign: "center" }}>
          이 제품은 정품입니다.
        </h3>
        <h4 className="color-gray" style={{ textAlign: "center" }}>
          Certificate of TAPTAG
        </h4>
      </div>

    </div >
  )
}


class Ads extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageIndex: "",

      imageUrl: "",
      imageLink: ""
    }

    this.count = 0;
    this.imageList = [];

  }

  async componentDidMount() {
    // console.log("props :", this.props)

    this.imageList = await this.newImageList()
    // console.log("compo-image List : ", this.imageList)

    // console.log("compo-imageList : ", this.imageList)
    this.setState({
      imageUrl: this.imageList[this.count].imgUrl,
      imageLink: this.imageList[this.count++].linkUrl
    })
    setInterval(() => {
      if (this.count > this.imageList.length - 1) {
        this.count = 0
      }
      // console.log("count : ", this.count)
      this.setState({
        imageUrl: this.imageList[this.count].imgUrl,
        imageLink: this.imageList[this.count++].linkUrl
      })
    }, 2000)
  }

  newImageList = async () => {

    const imageList = await Promise.all(
      this.props.ads.map((ad) => {
        return Storage.get(ad.imageFile)
          .then(data => {
            ad.imgUrl = data
            // console.log("data : ", data)
            return ad
          })
      })
    )
    return imageList
  }

  render() {
    return (
      <a className="ad-box" href={this.state.imageLink}>
        <img style={{ width: "100%" }} src={this.state.imageUrl} />
      </a>
    );
  }
}

class BrandAuth extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      minH: "500px",
      msg: "",
      imageUrl: "",
      imageFilename: "",
      authEnd: false,
      verified: false,
      mode: -1,
      ads: [],
      pName: "",
      pInfo: ""
    }

    this.getHotpTag = this.getHotpTag.bind(this);
    // this.getAd = this.getAd.bind(this);
    this.page = React.createRef();
  }



  _parseByMatchNSearch = () => {

    const params = this.props.match.params;
    // console.log("this.props.location.search : ", this.props.location.search)
    const q = queryString.parse(this.props.location.search);


    // console.log("q : ", q)
    return {
      adGroup: q.adGroup,
      ver: params.ver,
      uid: params.uid,
      cnt: q.cnt,
      hash: q.hash
    }
  }

  async componentDidMount() {
    this._setDefaultHeight();
    const parsed = this._parseByMatchNSearch();

    await this.getHotpTag(parsed);
    // console.log("this.state.mode!!: ", this.state.mode)
    // console.log("adgroup : ", parsed)
    let ads = await apiTagListAds({ adGroup: parsed.adGroup });
    // console.log("ads : ", ads)
    if (this.state.mode != 2) {
      this.setState({
        mode: 0
      })

      setTimeout(() => {
        this.setState({
          mode: 1
        })
      }, 3000);
    }

    // console.log("comdid-ads :", ads)
    this.setState({
      ads: ads.result.Items
    })
  }


  _setDefaultHeight = () => {

    const windowH = window.innerHeight;

    const bodyH = document.body.clientHeight;
    let pageRect = this.page.current.getBoundingClientRect();
    const pageH = pageRect.height;

    // 
    // bodyH 에서 pageH를 빼면 navbar의 height가 됨.
    // 
    const otherElementsH = bodyH - pageH;


    // 
    // windowH에서 other Elements의 H를 뺴면 이 full로 채울 page의 Height가 계산됨.
    // 
    let pageMinH = windowH - otherElementsH;

    if (pageMinH > 0) this.setState({ minH: windowH });
    // console.log("minH : ", pageMinH)

    // console.log('windowH', windowH);
    // console.log('bodyH', bodyH);
    // console.log('pageH', pageH);
    // console.log('minH', pageMinH);
  }

  async getHotpTag(opts) {
    let data = await apiTagVerifyByHotp2(opts);
    console.log("data : ", data.result.verified)

    if (data.result.verified != true) {
      // console.log("fake NFC card")
      this.setState({ mode: 2 })
    }

    if (!data.status || !data.result.verified)
      return this.setState({ authEnd: true });


    const tag = data.result;
    let msg = "";

    const info = JSON.parse(tag.optionalInfo);
    let editData = info

    for (let i = 0; i < editData.length; i++) {
      // console.log("object key", Object.keys(data[i]))
      // console.log("data : ", data[i])
      if (Object.keys(editData[i]) == "제품명") {
        let pName = editData.splice(i, 1)
        // this.setState
        console.log("pName : ", pName[0].["제품명"])
        this.setState({
          pName: pName[0].["제품명"]
        })
        i--;
      }

      else if (Object.keys(editData[i]) == "제품설명") {
        let pInfo = editData.splice(i, 1)
        console.log("pInfo : ", pInfo[0].["제품설명"])

        this.setState({
          pInfo: pInfo[0].["제품설명"]
        })
        i--;
      }
    }



    let imageUrl = await Storage.get(tag.imageFile)
    this.setState({
      info: info,
      msg: msg,
      tagImageUrl: imageUrl,
      tagImageFilename: info.imageFile,
      authEnd: true,
      verified: true,
    })
  }


  render() {
    const { authEnd, verified, msg, tagImageUrl, minH, mode, info, ads } = this.state;
    let failMsg = `<p style="margin: 5px">Sorry!!!</p>`;
    failMsg += `<p style="margin: 5px">You have a FAKE Product!!!</p>`;
    // console.log("[render]this.state.mode : ", mode)
    const loading = {
      loop: true,
      autoplay: true,
      animationData: require("./img/Loading1.json"),
      renderSetting: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    }
    const unAuth = {
      loop: true,
      autoplay: true,
      animationData: require("./img/fakeEmotion.json"),
      renderSetting: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    }



    return (
      <div ref={this.page} className="ba-auth container" style={{ height: minH, position: 'absolute', bottom: 0 }} >
        {this.state.mode === 0 &&
          <>
            <div className="item-container" >
              <div
                style={{
                  paddingTop: "65%",

                  height: "55%",
                  // backgroundColor: "black"
                }}
              >
                <Lottie options={loading} />
              </div>

              <div style={{ textAlign: "center", marginTop: "3%" }}>
                <h3 className="bold" style={{ textAlign: "center" }}>Loading</h3>
              </div>

            </div>
            <div className="ad-container">
              {
                ads.length > 0 &&
                <Ads ads={ads} />
              }
            </div>
          </>
        }

        {/* 정품인증화면 */}
        {
          this.state.mode === 1 &&
          <>
            <div className="item-container">
              <div style={{ height: "85%", paddingTop: "13%" }}>
                < VerifiedCard title="Verified OK" text={msg} imageSrc={tagImageUrl} />
              </div>
              <div style={{ height: "15%", marginTop: "5%" }}>
                <button className="bt" onClick={() => this.setState({ mode: 3 })} >
                  <h3 className="bold">자세히 보기</h3>
                </button>
              </div>
            </div>
            <div className="ad-container">
              {ads.length > 0 &&
                <Ads ads={ads} />
              }
            </div>
          </>
        }

        {/*정품이 아닐경우*/}
        {
          this.state.mode === 2 &&
          <div
            style={{
              marginTop: "60%",
              height: "25%",

            }}>
            <Lottie options={unAuth} />
            <div
              style={{
                marginTop: "3%"
              }}
            >
              <h2 style={{ textAlign: "center" }}>인식할 수 없음</h2>
            </div>
            <div>
              <h4 className="color-gray" style={{ textAlign: "center" }}>위·변조가 의심되는 제품입니다.</h4>
            </div>
          </div>
        }
        {/* 제품 자세히 보기 */}
        {
          this.state.mode === 3 &&
          <>
            <div className="dataInfo">
              <div style={{ height: "10%", paddingTop: "2.3%", }}>
                <button className="back-bt" onClick={() => this.setState({ mode: 1 })} >
                  <FiChevronLeft size={40} color="#a7a7a7" />
                </button>
              </div>
              <div style={{

                borderBottom: "6px solid #f3f3f3",
                height: "20%",
                marginBottom: "5%",
              }}>
                <div
                  style={{
                    width: "92%",
                    marginLeft: "5%",
                    paddingTop: "5%",
                  }}
                >
                  <h2 className="bold">
                    {this.state.pName}
                  </h2>
                  <h3 className="color-gray">
                    {this.state.pInfo}
                  </h3>
                </div>
              </div>

              <Scrollbars style={{ height: "65%", }}>
                {info.map((obj, index) => {
                  return (
                    <div key={index} className="dataInfo-wrap">
                      <div className="dataBox">
                        <div className="data-title"><h3 className="bold">{Object.keys(obj)}</h3></div>
                        <div className="data-info"><h3 className="color-gray">{Object.values(obj)}</h3></div>
                      </div>
                    </div>
                  )
                })}
              </Scrollbars>
            </div>
            <div className="ad-container">
              {ads.length > 0 &&
                <Ads ads={ads} />
              }
            </div>
          </>

        }

        {/* 광고 넣는 자리 */}
        <div className="ad-container">
          {/* < div style={{ backgroundColor: "black", height: "100%" }}>

          </div> */}


        </div>


      </div >
    )
  }
}

export default BrandAuth
