import React from "react";
import { Route, Switch } from "react-router-dom";
import AppliedRoute from "./components/AppliedRoute"
import NotFound from "./containers/NotFound"
// import Test from "./containers/Test"
import EchoLinq2 from "./containers/EchoLinq2"

export default function Routes({ appProps }) {
  return (
    <Switch>
      {/* <AppliedRoute path="/test" exact component={Test} appProps={appProps} />  */}
      <AppliedRoute path="/ba/:ver/:uid" exact component={EchoLinq2} appProps={appProps} />
      <Route component={NotFound} />
    </Switch>
  );
}
