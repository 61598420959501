const stage = "dev";
const region = "ap-northeast-2";

const dev = {
  s3: {
    REGION: region,
    BUCKET: "psdltag-api-dev-tagdatabucket-nvnb46v9wca9"
    // BUCKET: "test-psdl-images"
  },
  apiGateway: {
    REGION: region,
    PSDLTAG: {
      // URL: "https://hznogkt9ye.execute-api.ap-northeast-2.amazonaws.com/dev", // kebin account
      URL: "https://e9hmjo69bi.execute-api.ap-northeast-2.amazonaws.com/dev", // psdl account
      API_NAME: "tag",
    }
  },

  // psdl-cms
  cognito: {
    REGION: region,
    USER_POOL_ID: "ap-northeast-2_3S3UheIEP",
    APP_CLIENT_ID: "miq500bmncfg0u5m15gvd5r2n",
    IDENTITY_POOL_ID: "ap-northeast-2:59bac525-f1e8-4336-854d-77c12e73066e",
    IDENTITY_POOL_NAME: "psdltagIDPdev"
  }
};

const prod = dev;

const config = stage === 'prod' ? prod : dev;

export default config;