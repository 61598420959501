import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import { Navbar, Nav } from 'react-bootstrap'

import Routes from './Routes'
import './App.css';

function App(props) {

  const [isAuthenticating, setIsAuthenticating] = useState(true)
  const [isAuthenticated, userHasAuthenticated] = useState(false)

  const [username, setUsername] = useState('')

  /////////////////////////////////////////////////////////////////////////////////////
  // Same as compoementDidMount????
  // 1. If we don’t pass in an array of variables, 
  //    our hook gets executed everytime our component is rendered.
  // 2. If we pass in some variables, on every render React will first check 
  //    if those variables have changed, before running our function.
  // 3. If we pass in an empty list of variables, 
  //    then it’ll only run our function on the FIRST render.
  //    ==> In this case item 3 is effective.
  /////////////////////////////////////////////////////////////////////////////////////  
  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);

      /////////////////////////////////////////////////////////////////////////////////////
      // get User Attributes here and set user email | username
      // 1. Using retrun value, user
      // 2. Promise type
      /////////////////////////////////////////////////////////////////////////////////////
      let user = await Auth.currentUserPoolUser()
      // console.log(user)
      setUsername(user.attributes.email)
    }
    catch (e) {
      console.log(e)
    }

    setIsAuthenticating(false);
  }

  // async function handleLogout() {
  //   await Auth.signOut()
  //   userHasAuthenticated(false);

  //   /////////////////////////////////////////////////////////////////////////////////////
  //   // this MUST be used with "withRouter" because the history 
  //   //  is available in react-router-dom Router Compoment
  //   /////////////////////////////////////////////////////////////////////////////////////
  //   props.history.push('/login')
  // }

  return (
    !isAuthenticating &&  // Only if loggedIn, then render bellow
    <div className="App">
      {/* <Navbar collapseOnSelect expand="lg" variant="dark" className="nav-bg-blue">
        <Navbar.Brand>
          <Navbar.Text>TAPTAG</Navbar.Text>
        </Navbar.Brand>
        <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
          <Nav className="mr-auto"> */}

      {/* {
              isAuthenticated &&
              <>
                <NavDropdown title="Some Title" id="basic-nav-dropdown2">          
                  <LinkContainer to="/"><NavDropdown.Item>Some </NavDropdown.Item></LinkContainer>
                </NavDropdown>
              </>
            } */}
      {/* </Nav>

        </Navbar.Collapse>
      </Navbar> */}
      <Routes appProps={{ isAuthenticated, userHasAuthenticated, setUsername, username }} />
    </div>
  );
}

export default withRouter(App);
